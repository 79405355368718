/* Common Imports */

import { Box, styled } from "@mui/system";
import React from "react";

/* Redux Imports */

import {
	setCreateNePropertyMinBalconiesThunk,
	setCreateNewPropertyFloorNoThunk,
	setCreateNewPropertyFurnishingThunk,
	setCreateNewPropertyLandAreaThunk,
	setCreateNewPropertyLandAreaUnitThunk,
	setCreateNewPropertyMaxBalconiesThunk,
	setCreateNewPropertyMaxBathroomsThunk,
	setCreateNewPropertyMaxBedroomsThunk,
	setCreateNewPropertyMaxFloorsThunk,
	setCreateNewPropertyMaxSuperBuiltUpAreaThunk,
	setCreateNewPropertyMinBathroomsThunk,
	setCreateNewPropertyMinBedroomsThunk,
	setCreateNewPropertyMinSuperBuiltUpAreaThunk,
	setCreateNewPropertyNoOfSeatsThunk,
	setCreateNewPropertyNoOfUnitsThunk,
	setCreateNewPropertyPlotDimensionsThunk,
	setCreateNewPropertySuperBuiltUpAreaUnitThunk,
} from "../../../../../redux-magic/store";

/* Component Imports */

import {
	Checkbox,
	Chip,
	FormControl,
	FormHelperText,
	IconButton,
	InputLabel,
	ListItemText,
	MenuItem,
	OutlinedInput,
	Select,
	TextField,
	Typography,
	useTheme,
} from "@mui/material";

/* Icon Imports */

import { Add, DeleteOutline } from "@mui/icons-material";
import { useRouter } from "next/router";
import StateInterface from "../../../../../redux-magic/state-interface";

/* Styled Components */

const PaperContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-end",
	width: "100%",
	borderRadius: "8px",
	gap: "1.5rem",
	overflow: "auto",
	paddingTop: "1rem",
	// boxShadow: theme.palette.mode == "dark" ? "" : "0px 12px 24px -4px rgba(145, 158, 171, 0.12)",
	scrollbarWidth: "none", // Hide the scrollbar for firefox
	"&::-webkit-scrollbar": {
		display: "none", // Hide the scrollbar for WebKit browsers (Chrome, Safari, Edge, etc.)
	},
	"&-ms-overflow-style:": {
		display: "none", // Hide the scrollbar for IE
	},
	[theme.breakpoints.down("sm")]: {
		width: "100%",
	},
}));

const TextContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	// gap: "0.5rem",
	width: "100%",
}));

const Text = styled(Typography)(({ theme }) => ({
	margin: "0.5rem 0rem 0.5rem 0rem",
	fontWeight: "400",
	fontSize: "0.875rem",
	lineHeight: "1.125rem",
	whiteSpace: "pre",
}));

interface Area {
	label: string;
	enableLabel: boolean;
	land_area: {
		min: { ft: number; mt: number; acre: number };
		max: { ft: number; mt: number; acre: number };
	};
	super_built_up_area: {
		min: { ft: number; mt: number; acre: number };
		max: { ft: number; mt: number; acre: number };
	};
	carpet_area: {
		min: { ft: number; mt: number; acre: number };
		max: { ft: number; mt: number; acre: number };
	};
	karab_area: {
		min: { ft: number; mt: number; acre: number };
		max: { ft: number; mt: number; acre: number };
	};
}

interface BHK {
	min: number;
	max: number;
	label: string;
	enableLabel: boolean;
}

const PropertyConfig = ({
	propertyData,
	property_furnishing_status,
	property_plot_dimensions,
	property_area,
	property_bhk,
	property_bathroom,
	property_balconies,
	property_floors,
	property_floor_no,
	property_no_of_units,
	property_no_of_seats,
	dispatch,
	session,
	propertyBHKminError,
	setPropertyBHKminError,
	propertyBHKmaxError,
	setPropertyBHKmaxError,
	propertyNoOfUnitsError,
	setPropertyNoOfUnitsError,
	propertyNoOfSeatsError,
	setPropertyNoOfSeatsError,
	propertyPlotDimensionsError,
	setPropertyPlotDimensionsError,
	propertyLandAreaError,
	setPropertyLandAreaError,
	propertySuperBuiltUpMinAreaError,
	setPropertySuperBuiltUpMinAreaError,
	propertySuperBuiltUpMaxAreaError,
	setPropertySuperBuiltUpMaxAreaError,
}: {
	propertyData: any;
	property_furnishing_status: Array<string>;
	property_plot_dimensions: Array<string>;
	property_area: Area;
	property_bhk: BHK;
	property_bathroom: BHK;
	property_balconies: BHK;
	property_floors: BHK;
	property_floor_no: BHK;
	property_no_of_units: BHK;
	property_no_of_seats: BHK;
	dispatch: Function;
	session: StateInterface["redux_session"];
	propertyBHKminError: boolean;
	setPropertyBHKminError: Function;
	propertyBHKmaxError: boolean;
	setPropertyBHKmaxError: Function;
	propertyNoOfUnitsError: boolean;
	setPropertyNoOfUnitsError: Function;
	propertyNoOfSeatsError: boolean;
	setPropertyNoOfSeatsError: Function;
	propertyPlotDimensionsError: boolean;
	setPropertyPlotDimensionsError: Function;
	propertyLandAreaError: boolean;
	setPropertyLandAreaError: Function;
	propertySuperBuiltUpMinAreaError: boolean;
	setPropertySuperBuiltUpMinAreaError: Function;
	propertySuperBuiltUpMaxAreaError: boolean;
	setPropertySuperBuiltUpMaxAreaError: Function;
}) => {
	const theme = useTheme();

	const plot_dimensions = ["20 x 30", "30 x 40", "30 x 50", "40 x 60", "50 x 80", "60 x 90", "80 x 120", "Other"];

	const [isEditing, setIsEditing] = React.useState(false);

	const [landAreaUnit, setLandAreaUnit] = React.useState<string>("acre");

	const [superBuiltUpAreaUnit, setSuperBuiltUpAreaUnit] = React.useState<string>("sqft");

	const [ca_is_percentage_of_sba, setCaIsPercentageOfSba] = React.useState<boolean>(false);

	const [minCarpetAreaPercentage, setMinCarpetAreaPercentage] = React.useState<number>(70);

	const [maxCarpetAreaPercentage, setMaxCarpetAreaPercentage] = React.useState<number>(70);

	const [carpetAreaUnit, setCarpetAreaUnit] = React.useState<string>("");

	const newDelimitedSearchString = propertyData.search_metrics
		? propertyData.search_metrics.search_string_delimiter.split("ߐ")
		: [];

	const [submitDisabled, setSubmitDisabled] = React.useState<boolean>(false);

	const [errorBedroom, setErrorBedroom] = React.useState<boolean>(false);

	const [errorMinBedroom, setErrorMinBedroom] = React.useState<boolean>(false);

	const [errorMaxBedroom, setErrorMaxBedroom] = React.useState<boolean>(false);

	const [errorBathroom, setErrorBathroom] = React.useState<boolean>(false);

	const [errorMinBathroom, setErrorMinBathroom] = React.useState<boolean>(false);

	const [errorMaxBathroom, setErrorMaxBathroom] = React.useState<boolean>(false);

	const [errorNoOfSeats, setErrorNoOfSeats] = React.useState<boolean>(false);

	const [errorNoOfUnits, setErrorNoOfUnits] = React.useState<boolean>(false);

	const [errorMaxFloors, setErrorMaxFloors] = React.useState<boolean>(false);

	const [errorMinOtherPlot, setErrorMinOtherPlot] = React.useState<Array<boolean>>(
		new Array(property_plot_dimensions.filter((item) => !plot_dimensions.includes(item)).length).fill(false),
	);

	const [errorMaxOtherPlot, setErrorMaxOtherPlot] = React.useState<Array<boolean>>(
		new Array(property_plot_dimensions.filter((item) => !plot_dimensions.includes(item)).length).fill(false),
	);

	const [errorLandArea, setErrorLandArea] = React.useState<boolean>(false);

	const [errorSba, setErrorSba] = React.useState<boolean>(false);

	const [errorMinSba, setErrorMinSba] = React.useState<boolean>(false);

	const [errorMaxSba, setErrorMaxSba] = React.useState<boolean>(false);

	const [errorMinCa, setErrorMinCa] = React.useState<boolean>(false);

	const [errorMaxCa, setErrorMaxCa] = React.useState<boolean>(false);

	const router = React.useRef(useRouter()).current;

	const localLang = {
		furnishing: router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ಫರ್ನಿಶಿಂಗ್" : "Furnishing",
		furnished: router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ಫರ್ನಿಶ್ಡ್" : "Furnished",
		semiFurnished: router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ಸೆಮಿ ಫರ್ನಿಶ್ಡ್" : "Semi furnished",
		unfurnished: router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ಅನ್ಫರ್ನಿಶ್ಡ್" : "Unfurnished",
		validNumberError:
			router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ಮಾನ್ಯ ಸಂಖ್ಯೆಯನ್ನು ನಮೂದಿಸಿ" : "Enter a valid number",
		bedrooms: router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ಬೆಡ್ರೂಮ್*" : "Bedrooms*",
		minBedroom: router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ಕನಿಷ್ಠ ಬೆಡ್ರೂಮ್*" : "Minimum Bedrooms*",
		valueMoreThanMaxBedroom:
			router.locale === "ar-AE"
				? ""
				: router.locale === "kn-IN"
					? "ಮೌಲ್ಯವು ಗರಿಷ್ಠ ಬೆಡ್ರೂಮ್‌ಗಿಂತ ಹೆಚ್ಚು ಇದೆ"
					: "Value is more than max bedrooms",
		maxBedrooms: router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ಗರಿಷ್ಠ ಬೆಡ್ರೂಮ್*" : "Maximum Bedrooms*",
		valueLessThanMinBedrooms:
			router.locale === "ar-AE"
				? ""
				: router.locale === "kn-IN"
					? "ಮೌಲ್ಯವು ಕನಿಷ್ಠ ಬೆಡ್ರೂಮ್‌ಗಿಂತ ಕಡಿಮೆ ಇದೆ"
					: "Value is less than min bedrooms",
		bathrooms: router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ಬಾತ್ರೂಮ್" : "Bathrooms",
		valueMoreThanMaxBathroom:
			router.locale === "ar-AE"
				? ""
				: router.locale === "kn-IN"
					? "ಮೌಲ್ಯವು ಗರಿಷ್ಠ ಬಾತ್‌ರೂಮ್‌ಗಿಂತ ಹೆಚ್ಚು ಇದೆ"
					: "Value is more than max bathrooms",
		minBathroom: router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ಕನಿಷ್ಠ ಬಾತ್ರೂಮ್" : "Minimum Bathrooms",
		valuelessThanMinBathroom:
			router.locale === "ar-AE"
				? ""
				: router.locale === "kn-IN"
					? "ಮೌಲ್ಯವು ಕನಿಷ್ಠ ಬಾತ್‌ರೂಮ್‌ಗಿಂತ ಕಡಿಮೆ ಇದೆ"
					: "Value is less than min bathrooms",
		maxBathroom: router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ಗರಿಷ್ಠ ಬಾತ್ರೂಮ್" : "Maximum Bathrooms",
		balconies: router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ಬ್ಯಾಲ್ಕನಿಗಳು" : "Balconies",
		noOfSeats: router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ಸೀಟ್ಗಳ ಸಂಖ್ಯೆ*" : "No. of seats*",
		totalNoOfUnits:
			router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ಒಟ್ಟು ಯೂನಿಟ್‌ಗಳು*" : "Total no. of units*",
		totalNoOfFloors:
			router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ಒಟ್ಟು ಮಹಡಿಗಳು" : "Total no. of floors",
		plotDimention: router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ಪ್ಲಾಟ್ ಅಳತೆ*" : "Plot dimensions*",
		selectValidplotDimention:
			router.locale === "ar-AE"
				? ""
				: router.locale === "kn-IN"
					? "ಮಾನ್ಯ ಪ್ಲಾಟ್ ಅಳತೆಯನ್ನು ಆಯ್ಕೆಮಾಡಿ"
					: "Select a valid plot dimension",
		otherPlotDimentions:
			router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ಇತರ ಪ್ಲಾಟ್ ಅಳತೆಗಳು :" : "Other plot dimensions :",
		length: router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ಉದ್ದ" : "Length",
		invalidNumber: router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ಅಮಾನ್ಯ ಸಂಖ್ಯೆ" : "Invalid number",
		width: router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ಅಗಲ" : "Width",
		addMore: router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ಹೆಚ್ಚು ಸೇರಿಸಿ" : "Add more",
		totalLandArea:
			router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ಒಟ್ಟು ಭೂಮಿಯ ಪ್ರದೇಶ" : "Total land area",
		totalLandAreaConfirmation:
			router.locale === "ar-AE"
				? ""
				: router.locale === "kn-IN"
					? "ಇದು ಕಟ್ಟಡವು ನಿಂತಿರುವ ಪೂರ್ಣ ಭೂಮಿಯ ಗಾತ್ರ"
					: "It is the size of the the entire land where the building stands",
		landArea: router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ಭೂಮಿಯ ಪ್ರದೇಶ" : "Land area",
		unit: router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ಯುನಿಟ್" : "Unit",
		acre: router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ಎಕರೆ" : "acre",
		sqft: router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ಚದರ ಅಡಿ" : "sqft",
		sqm: router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ಚದರ ಮೀಟರ್" : "sqm",
		SBUAInfo:
			router.locale === "ar-AE"
				? ""
				: router.locale === "kn-IN"
					? "ಇದು ಮುಂಭಾಗದ ಜಾಗವನ್ನು ಮತ್ತು ಸಾಮಾನ್ಯ ಪ್ರದೇಶಗಳು ಮತ್ತು ಸೌಲಭ್ಯಗಳ ಪ್ರಪೋರ್ಷನಲ್ ಹಂಚಿಕೆಯನ್ನು ಒಳಗೊಂಡ ಒಟ್ಟು ಪ್ರದೇಶವಾಗಿದೆ"
					: "It is the total area that includes the living space and a proportionate share of common areas and amenities",
		area: router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ಪ್ರದೇಶ*" : "Area*",
		valueMoreThanMaxArea:
			router.locale === "ar-AE"
				? ""
				: router.locale === "kn-IN"
					? "ಮೌಲ್ಯವು ಗರಿಷ್ಠ ಪ್ರದೇಶದಿಂದ ಹೆಚ್ಚು ಇದೆ"
					: "Value is more than max area",
		minArea: router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ಕನಿಷ್ಠ ಪ್ರದೇಶ*" : "Minimum area*",
		valueLessThanMinArea:
			router.locale === "ar-AE"
				? ""
				: router.locale === "kn-IN"
					? "ಮೌಲ್ಯವು ಕನಿಷ್ಠ ಪ್ರದೇಶದಿಂದ ಕಡಿಮೆ ಇದೆ"
					: "Value is less than min area",
		maxArea: router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ಗರಿಷ್ಠ ಪ್ರದೇಶ*" : "Maximum area*",
		superBuiltUpArea:
			router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ಸೂಪರ್ ಬಿಲ್ಟ್ ಅಪ್ ಪ್ರದೇಶ" : "Super built-up area",
		plotArea: router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ಪ್ಲಾಟ್ ಪ್ರದೇಶ" : "Plot area",
	};

	const scrollTo = (elementId: string) => {
		const element = document.getElementById(elementId);
		if (element) {
			element.scrollIntoView({ behavior: "smooth", block: "start" });
		}
	};

	React.useEffect(() => {
		if (propertyBHKminError || propertyBHKmaxError || errorBedroom) {
			scrollTo("property-bedroom");
		} else if (propertyNoOfSeatsError) {
			scrollTo("property-no-of-seats");
		} else if (propertyNoOfUnitsError) {
			scrollTo("property-no-of-units");
		} else if (propertyPlotDimensionsError) {
			scrollTo("property-plot-dimensions");
		} else if (propertyLandAreaError) {
			scrollTo("property-land-area");
		} else if (propertySuperBuiltUpMinAreaError || propertySuperBuiltUpMaxAreaError) {
			scrollTo("property-super-built-up-area");
		}
	}, [
		errorBedroom,
		propertyBHKminError,
		propertyBHKmaxError,
		propertyNoOfSeatsError,
		propertyNoOfUnitsError,
		propertyPlotDimensionsError,
		propertyLandAreaError,
		propertySuperBuiltUpMinAreaError,
		propertySuperBuiltUpMaxAreaError,
	]);

	return (
		<PaperContainer id="config-area">
			{/* Furnishing */}
			{/* {propertyData.transaction_type !== "ofb" &&
			propertyData.transaction_type !== "" &&
			propertyData.property_category !== "" &&
			propertyData.property_type.length !== 0 &&
			!propertyData.property_type.includes("Plot") &&
			!propertyData.property_type.includes("Farm Plot") &&
			!propertyData.property_type.includes("Plot (Commercial)") &&
			!propertyData.property_type.includes("Land (Residential)") &&
			!propertyData.property_type.includes("Land (Commercial)") &&
			!propertyData.property_type.includes("Land (Agricultural)") &&
			!propertyData.property_type.includes("Land (Industrial)") &&
			!propertyData.property_type.includes("Co-working") &&
			!propertyData.property_type.includes("Warehouse") &&
			!propertyData.property_type.includes("Factory") &&
			!propertyData.property_type.includes("Mining") ? (
				<FormControl
					sx={{
						width: "100%",
						margin: "0rem",
						[theme.breakpoints.down("sm")]: {
							margin: "0rem 0rem 0rem 0.5rem",
						},
						"& .MuiOutlinedInput-root": {
							borderRadius: "0.75rem",
						},
					}}
				>
					<InputLabel id="demo-simple-select-helper-label">{localLang.furnishing}</InputLabel>
					<Select
						labelId="demo-simple-select-helper-label"
						id="demo-simple-select-helper"
						value={property_furnishing_status ? property_furnishing_status : []}
						sx={{
							width: "100%",
							[theme.breakpoints.down("sm")]: {
								minWidth: "100%",
							},
							"& .MuiOutlinedInput-root": {
								borderRadius: "0.75rem",
							},
						}}
						MenuProps={{
							PaperProps: {
								sx: {
									"& .MuiMenu-list": {
										direction: router.locale === "ar-AE" ? "rtl" : "ltr",
									},
								},
							},
						}}
						label={localLang.furnishing}
						onChange={(event: any) => {
							dispatch(setCreateNewPropertyFurnishingThunk([event.target.value]));
						}}
					>
						<MenuItem value="furnished">{localLang.furnished}</MenuItem>
						<MenuItem value="semi_furnished">{localLang.semiFurnished}</MenuItem>
						<MenuItem value="unfurnished">{localLang.unfurnished}</MenuItem>
					</Select>
				</FormControl>
			) : null} */}

			{propertyData.transaction_type !== "ofb" &&
			propertyData.transaction_type !== "" &&
			propertyData.property_category !== "" &&
			propertyData.property_type.length !== 0 &&
			!propertyData.property_type.includes("Plot") &&
			!propertyData.property_type.includes("Farm Plot") &&
			!propertyData.property_type.includes("Plot (Commercial)") &&
			!propertyData.property_type.includes("Land (Residential)") &&
			!propertyData.property_type.includes("Land (Commercial)") &&
			!propertyData.property_type.includes("Land (Agricultural)") &&
			!propertyData.property_type.includes("Land (Industrial)") &&
			!propertyData.property_type.includes("Co-working") &&
			!propertyData.property_type.includes("Warehouse") &&
			!propertyData.property_type.includes("Factory") &&
			!propertyData.property_type.includes("Mining") ? (
				<FormControl
					sx={{
						width: "100%",
					}}
				>
					<Typography
						sx={{
							fontFamily: "Poppins",
							fontSize: "14px",
							fontStyle: "normal",
							fontWeight: 500,
							lineHeight: "157%",
							letterSpacing: "0.1px",
							mb: "10px",
						}}
					>
						{localLang.furnishing}
					</Typography>

					<Box
						sx={{
							display: "flex",
							flexWrap: "wrap",
							gap: "0.5rem",
						}}
					>
						{[
							{ value: "furnished", label: localLang.furnished },
							{ value: "semi_furnished", label: localLang.semiFurnished },
							{ value: "unfurnished", label: localLang.unfurnished },
						].map((item) => {
							const isSelected = property_furnishing_status?.includes(item.value);
							return (
								<Chip
									key={item.value}
									label={item.label}
									clickable
									onClick={() => {
										dispatch(setCreateNewPropertyFurnishingThunk([item.value]));
									}}
									sx={{
										textAlign: "center",
										borderRadius: "8px",
										border: isSelected
											? "1px solid var(--Primary-New-Dark, #B05911)"
											: "1px solid var(--Other-New-Divider, rgba(0, 0, 0, 0.12))",
										backgroundColor: isSelected ? "#FFEBDA" : "#fff",
										color: isSelected ? "#B05911" : "inherit",
										px: 2.5,
										py: 1,
										fontWeight: 400,
										fontSize: "14px",
										height: "40px",
									}}
								/>
							);
						})}
					</Box>
				</FormControl>
			) : null}

			{/* Bedrooms */}

			{/* {propertyData.transaction_type !== "ofb" &&
			propertyData.transaction_type !== "" &&
			propertyData.property_category !== "" &&
			propertyData.property_type.length !== 0 &&
			!propertyData.property_type.includes("Plot") &&
			!propertyData.property_type.includes("Farm Plot") &&
			!propertyData.property_type.includes("Plot (Commercial)") &&
			!propertyData.property_type.includes("Land (Residential)") &&
			!propertyData.property_type.includes("Land (Commercial)") &&
			!propertyData.property_type.includes("Land (Agricultural)") &&
			!propertyData.property_type.includes("Land (Industrial)") &&
			!propertyData.property_type.includes("Studio Apartment") &&
			!propertyData.property_type.includes("Building") &&
			!propertyData.property_type.includes("Shop") &&
			!propertyData.property_type.includes("Office Space") &&
			!propertyData.property_type.includes("Co-working") &&
			!propertyData.property_type.includes("Warehouse") &&
			!propertyData.property_type.includes("Factory") &&
			!propertyData.property_type.includes("Mining") ? (
				<TextContainer id="property-bedroom">
					{propertyData.transaction_type !== "buy" ||
					propertyData.property_type.includes("Co-living/PG") ||
					propertyData.property_type.includes("Independent House") ? (
						<TextField
							value={property_bhk.max === 0 ? "" : property_bhk.max}
							sx={{
								width: "100%",
								[theme.breakpoints.down("sm")]: {
									margin: "0rem",
									minWidth: "100%",
								},
								"& .MuiOutlinedInput-root": {
									borderRadius: "0.75rem",
								},
							}}
							autoComplete="off"
							slotProps={{
								htmlInput: {
									min: 0,
									inputMode: "numeric",
									pattern: "[0-9]*",
								},
							}}
							onKeyUp={(event: React.KeyboardEvent<HTMLInputElement>) => {
								if (event.key === "Enter" || event.key === "Next") {
									(event.target as HTMLInputElement).blur();
								}
							}}
							onChange={(event: any) => {
								dispatch(setCreateNewPropertyMinBedroomsThunk(Number(event.target.value.replace(/\D/g, ""))));
								dispatch(setCreateNewPropertyMaxBedroomsThunk(Number(event.target.value.replace(/\D/g, ""))));
								if (isNaN(Number(event.target.value.replace(/\D/g, "")))) {
									setErrorBedroom(true);
									setSubmitDisabled(true);
								} else {
									setErrorBedroom(false);
									setSubmitDisabled(false);
								}
							}}
							error={errorBedroom || propertyBHKmaxError}
							helperText={errorBedroom || propertyBHKmaxError ? localLang.validNumberError : ""}
							label={localLang.bedrooms}
						/>
					) : (
						<TextContainer
							sx={{
								gap: "0.5rem",
							}}
						>
							<TextField
								value={property_bhk.min === 0 ? "" : property_bhk.min}
								sx={{
									width: "50%",
									// [theme.breakpoints.down("sm")]: {
									// 	margin: "0rem",
									// 	minWidth: "100%",
									// },
									"& .MuiOutlinedInput-root": {
										borderRadius: "0.75rem",
									},
								}}
								autoComplete="off"
								slotProps={{
									htmlInput: {
										min: 0,
										inputMode: "numeric",
										pattern: "[0-9]*",
									},
								}}
								onKeyUp={(event: React.KeyboardEvent<HTMLInputElement>) => {
									if (event.key === "Enter" || event.key === "Next") {
										(event.target as HTMLInputElement).blur();
									}
								}}
								onChange={(event: any) => {
									dispatch(setCreateNewPropertyMinBedroomsThunk(Number(event.target.value.replace(/\D/g, ""))));
									if (
										(property_bhk.max !== 0 && Number(event.target.value.replace(/\D/g, "")) > property_bhk.max) ||
										isNaN(Number(event.target.value.replace(/\D/g, "")))
									) {
										setErrorMinBedroom(true);
										setErrorMaxBedroom(true);
										setSubmitDisabled(true);
									} else {
										setErrorMinBedroom(false);
										setErrorMaxBedroom(false);
										setSubmitDisabled(false);
										setPropertyBHKminError(false);
									}
								}}
								error={errorMinBedroom || propertyBHKminError}
								label={localLang.minBedroom}
								helperText={errorMinBedroom || propertyBHKminError ? localLang.valueMoreThanMaxBedroom : ""}
							/>

							<TextField
								value={property_bhk.max === 0 ? "" : property_bhk.max}
								sx={{
									width: "50%",
									// [theme.breakpoints.down("sm")]: {
									// 	margin: "0rem",
									// 	minWidth: "100%",
									// },
									"& .MuiOutlinedInput-root": {
										borderRadius: "0.75rem",
									},
								}}
								autoComplete="off"
								slotProps={{
									htmlInput: {
										min: 0,
										inputMode: "numeric",
										pattern: "[0-9]*",
									},
								}}
								onKeyUp={(event: React.KeyboardEvent<HTMLInputElement>) => {
									if (event.key === "Enter" || event.key === "Next") {
										(event.target as HTMLInputElement).blur();
									}
								}}
								onChange={(event: any) => {
									dispatch(setCreateNewPropertyMaxBedroomsThunk(Number(event.target.value.replace(/\D/g, ""))));
									if (
										(property_bhk.min !== 0 && Number(event.target.value.replace(/\D/g, "")) < property_bhk.min) ||
										isNaN(Number(event.target.value.replace(/\D/g, "")))
									) {
										setErrorMinBedroom(true);
										setErrorMaxBedroom(true);
										setSubmitDisabled(true);
									} else {
										setErrorMinBedroom(false);
										setErrorMaxBedroom(false);
										setSubmitDisabled(false);
										setPropertyBHKmaxError(false);
									}
								}}
								error={errorMaxBedroom || propertyBHKmaxError}
								helperText={errorMaxBedroom || propertyBHKmaxError ? localLang.valueLessThanMinBedrooms : ""}
								label={localLang.maxBedrooms}
							/>
						</TextContainer>
					)}
				</TextContainer>
			) : null} */}

			{propertyData.transaction_type !== "ofb" &&
				propertyData.transaction_type !== "" &&
				propertyData.property_category !== "" &&
				propertyData.property_type.length !== 0 &&
				!propertyData.property_type.includes("Plot") &&
				!propertyData.property_type.includes("Farm Plot") &&
				!propertyData.property_type.includes("Plot (Commercial)") &&
				!propertyData.property_type.includes("Land (Residential)") &&
				!propertyData.property_type.includes("Land (Commercial)") &&
				!propertyData.property_type.includes("Land (Agricultural)") &&
				!propertyData.property_type.includes("Land (Industrial)") &&
				!propertyData.property_type.includes("Studio Apartment") &&
				!propertyData.property_type.includes("Building") &&
				!propertyData.property_type.includes("Shop") &&
				!propertyData.property_type.includes("Office Space") &&
				!propertyData.property_type.includes("Co-working") &&
				!propertyData.property_type.includes("Warehouse") &&
				!propertyData.property_type.includes("Factory") &&
				!propertyData.property_type.includes("Mining") && (
					<TextContainer id="property-bedroom">
						{propertyData.transaction_type !== "buy" ||
						propertyData.property_type.includes("Co-living/PG") ||
						propertyData.property_type.includes("Independent House") ? (
							<FormControl sx={{ width: "100%" }}>
								<Typography
									sx={{
										fontFamily: "Poppins",
										fontSize: "14px",
										fontWeight: 500,
										lineHeight: "157%",
										letterSpacing: "0.1px",
										mb: "10px",
									}}
								>
									{localLang.bedrooms}
								</Typography>

								<Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", gap: "0.5rem" }}>
									{["1", "2", "3", "4", "5+"].map((item) => {
										const val = item === "5+" ? 5 : parseInt(item);
										const isSelected = property_bhk.max === val;

										return (
											<Chip
												key={item}
												label={item}
												clickable
												onClick={() => {
													dispatch(setCreateNewPropertyMinBedroomsThunk(val));
													dispatch(setCreateNewPropertyMaxBedroomsThunk(val));
													setErrorBedroom(false);
													setSubmitDisabled(false);
												}}
												sx={{
													textAlign: "center",
													borderRadius: "8px",
													border: isSelected
														? "1px solid var(--Primary-New-Dark, #B05911)"
														: "1px solid var(--Other-New-Divider, rgba(0, 0, 0, 0.12))",
													backgroundColor: isSelected ? "#FFEBDA" : "#fff",
													color: isSelected ? "#B05911" : "inherit",

													fontWeight: 500,
													fontSize: "14px",
													height: "40px",
													padding: "0.75rem",
												}}
											/>
										);
									})}
								</Box>

								{(errorBedroom || propertyBHKmaxError) && (
									<FormHelperText error>{localLang.validNumberError}</FormHelperText>
								)}
							</FormControl>
						) : (
							<TextContainer sx={{ gap: "1.5rem", flexDirection: "column" }}>
								{/* Min Bedrooms */}
								<FormControl sx={{ width: "100%" }}>
									<Typography
										sx={{
											fontFamily: "Poppins",
											fontSize: "14px",
											fontWeight: 500,
											lineHeight: "157%",
											letterSpacing: "0.1px",
											mb: "10px",
										}}
									>
										{localLang.minBedroom}
									</Typography>
									<Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", gap: "0.5rem" }}>
										{["1", "2", "3", "4", "5+"].map((item) => {
											const val = item === "5+" ? 5 : parseInt(item);
											const isSelected = property_bhk.min === val;

											return (
												<Chip
													key={item}
													label={item}
													clickable
													onClick={() => {
														dispatch(setCreateNewPropertyMinBedroomsThunk(val));
														if (property_bhk.max !== 0 && val > property_bhk.max) {
															setErrorMinBedroom(true);
															setErrorMaxBedroom(true);
															setSubmitDisabled(true);
														} else {
															setErrorMinBedroom(false);
															setErrorMaxBedroom(false);
															setSubmitDisabled(false);
															setPropertyBHKminError(false);
														}
													}}
													sx={{
														textAlign: "center",
														borderRadius: "8px",
														border: isSelected
															? "1px solid var(--Primary-New-Dark, #B05911)"
															: "1px solid var(--Other-New-Divider, rgba(0, 0, 0, 0.12))",
														backgroundColor: isSelected ? "#FFEBDA" : "#fff",
														color: isSelected ? "#B05911" : "inherit",

														fontWeight: 400,
														fontSize: "14px",
														height: "40px",
														padding: "0.75rem",
													}}
												/>
											);
										})}
									</Box>
									{(errorMinBedroom || propertyBHKminError) && (
										<FormHelperText error>{localLang.valueMoreThanMaxBedroom}</FormHelperText>
									)}
								</FormControl>

								{/* Max Bedrooms */}
								<FormControl sx={{ width: "100%" }}>
									<Typography
										sx={{
											fontFamily: "Poppins",
											fontSize: "14px",
											fontWeight: 500,
											lineHeight: "157%",
											letterSpacing: "0.1px",
											mb: "10px",
										}}
									>
										{localLang.maxBedrooms}
									</Typography>
									<Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", gap: "0.5rem" }}>
										{["1", "2", "3", "4", "5+"].map((item) => {
											const val = item === "5+" ? 5 : parseInt(item);
											const isSelected = property_bhk.max === val;

											return (
												<Chip
													key={item}
													label={item}
													clickable
													onClick={() => {
														dispatch(setCreateNewPropertyMaxBedroomsThunk(val));
														if (property_bhk.min !== 0 && val < property_bhk.min) {
															setErrorMinBedroom(true);
															setErrorMaxBedroom(true);
															setSubmitDisabled(true);
														} else {
															setErrorMinBedroom(false);
															setErrorMaxBedroom(false);
															setSubmitDisabled(false);
															setPropertyBHKmaxError(false);
														}
													}}
													sx={{
														textAlign: "center",
														borderRadius: "8px",
														border: isSelected
															? "1px solid var(--Primary-New-Dark, #B05911)"
															: "1px solid var(--Other-New-Divider, rgba(0, 0, 0, 0.12))",
														backgroundColor: isSelected ? "#FFEBDA" : "#fff",
														color: isSelected ? "#B05911" : "inherit",

														fontWeight: 400,
														fontSize: "14px",
														height: "40px",
														padding: "0.75rem",
													}}
												/>
											);
										})}
									</Box>
									{(errorMaxBedroom || propertyBHKmaxError) && (
										<FormHelperText error>{localLang.valueLessThanMinBedrooms}</FormHelperText>
									)}
								</FormControl>
							</TextContainer>
						)}
					</TextContainer>
				)}

			{/* <FormControl sx={{ width: "100%" }}>
				<Typography
					sx={{
						fontFamily: "Poppins",
						fontSize: "14px",
						fontWeight: 500,
						lineHeight: "157%",
						letterSpacing: "0.1px",
						mb: "10px",
					}}
				>
					{localLang.minBedroom}
				</Typography>

				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "space-between",
						gap: "0.5rem",
					}}
				>
					{["0", "1", "2", "3", "4", "5+"].map((item) => {
						const val = item === "5+" ? 5 : parseInt(item);
						const isSelected = property_bhk.min === val;

						return (
							<Chip
								key={item}
								label={item}
								clickable
								onClick={() => {
									dispatch(setCreateNewPropertyMinBedroomsThunk(val));
									setErrorMinBedroom(false);
									setPropertyBHKminError(false);
								}}
								sx={{
									textAlign: "center",
									borderRadius: "8px",
									border: isSelected
										? "1px solid var(--Primary-New-Dark, #B05911)"
										: "1px solid var(--Other-New-Divider, rgba(0, 0, 0, 0.12))",
									backgroundColor: isSelected ? "#FFEBDA" : "#fff",
									color: isSelected ? "#B05911" : "inherit",
									px: 2.5,
									py: 1,
									fontWeight: 400,
									fontSize: "14px",
									height: "40px",
									padding: "0.5rem",
								}}
							/>
						);
					})}
				</Box>
				{(errorMinBedroom || propertyBHKminError) && (
					<FormHelperText error>{localLang.valueMoreThanMaxBedroom}</FormHelperText>
				)}
			</FormControl>

			<FormControl sx={{ width: "100%" }}>
				<Typography
					sx={{
						fontFamily: "Poppins",
						fontSize: "14px",
						fontWeight: 500,
						lineHeight: "157%",
						letterSpacing: "0.1px",
						mb: "10px",
					}}
				>
					{localLang.maxBedrooms}
				</Typography>

				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "space-between",
						gap: "0.5rem",
					}}
				>
					{["1", "2", "3", "4", "5+"].map((item) => {
						const val = item === "5+" ? 5 : parseInt(item);
						const isSelected = property_bhk.max === val;

						return (
							<Chip
								key={item}
								label={item}
								clickable
								onClick={() => {
									dispatch(setCreateNewPropertyMaxBedroomsThunk(val));
									setErrorMaxBedroom(false);
									setPropertyBHKmaxError(false);
								}}
								sx={{
									textAlign: "center",
									borderRadius: "8px",
									border: isSelected
										? "1px solid var(--Primary-New-Dark, #B05911)"
										: "1px solid var(--Other-New-Divider, rgba(0, 0, 0, 0.12))",
									backgroundColor: isSelected ? "#FFEBDA" : "#fff",
									color: isSelected ? "#B05911" : "inherit",
									px: 2.5,
									py: 1,
									fontWeight: 400,
									fontSize: "14px",
									height: "40px",
									padding: "0.5rem",
								}}
							/>
						);
					})}
				</Box>
				{(errorMaxBedroom || propertyBHKmaxError) && (
					<FormHelperText error>{localLang.valueLessThanMinBedrooms}</FormHelperText>
				)}
			</FormControl> */}

			{/* Bathrooms */}

			{/* {propertyData.transaction_type !== "ofb" &&
			propertyData.transaction_type !== "" &&
			propertyData.property_category !== "" &&
			propertyData.property_type.length !== 0 &&
			!propertyData.property_type.includes("Co-living/PG") &&
			!propertyData.property_type.includes("Plot") &&
			!propertyData.property_type.includes("Farm Plot") &&
			!propertyData.property_type.includes("Plot (Commercial)") &&
			!propertyData.property_type.includes("Land (Residential)") &&
			!propertyData.property_type.includes("Land (Commercial)") &&
			!propertyData.property_type.includes("Land (Agricultural)") &&
			!propertyData.property_type.includes("Land (Industrial)") &&
			!propertyData.property_type.includes("Studio Apartment") &&
			!propertyData.property_type.includes("Building") &&
			!propertyData.property_type.includes("Shop") &&
			!propertyData.property_type.includes("Office Space") &&
			!propertyData.property_type.includes("Co-working") &&
			!propertyData.property_type.includes("Warehouse") &&
			!propertyData.property_type.includes("Factory") &&
			!propertyData.property_type.includes("Mining") ? (
				<TextContainer>
					{(propertyData.transaction_type !== "buy" && !propertyData.property_type.includes("Co-living/PG")) ||
					propertyData.property_type.includes("Independent House") ? (
						<TextContainer>
							<TextField
								value={property_bathroom.max === 0 ? "" : property_bathroom.max}
								sx={{
									width: "100%",
									[theme.breakpoints.down("sm")]: {
										margin: "0rem",
										minWidth: "100%",
									},
									"& .MuiOutlinedInput-root": {
										borderRadius: "0.75rem",
									},
								}}
								autoComplete="off"
								slotProps={{
									htmlInput: {
										min: 0,
										inputMode: "numeric",
										pattern: "[0-9]*",
									},
								}}
								onKeyUp={(event: React.KeyboardEvent<HTMLInputElement>) => {
									if (event.key === "Enter" || event.key === "Next") {
										(event.target as HTMLInputElement).blur();
									}
								}}
								onChange={(event: any) => {
									dispatch(setCreateNewPropertyMinBathroomsThunk(Number(event.target.value.replace(/\D/g, ""))));
									dispatch(setCreateNewPropertyMaxBathroomsThunk(Number(event.target.value.replace(/\D/g, ""))));
									if (isNaN(Number(event.target.value.replace(/\D/g, "")))) {
										setErrorBathroom(true);
										setSubmitDisabled(true);
									} else {
										setErrorBathroom(false);
										setSubmitDisabled(false);
									}
								}}
								error={errorBathroom}
								helperText={errorBathroom ? localLang.validNumberError : ""}
								label={localLang.bathrooms}
							/>
						</TextContainer>
					) : (
						<TextContainer
							sx={{
								"& .MuiOutlinedInput-root": {
									borderRadius: "0.75rem",
								},
								gap: "0.5rem",
							}}
						>
							<TextField
								value={property_bathroom.min === 0 ? "" : property_bathroom.min}
								slotProps={{
									htmlInput: {
										min: 0,
										inputMode: "numeric",
										pattern: "[0-9]*",
									},
								}}
								onKeyUp={(event: React.KeyboardEvent<HTMLInputElement>) => {
									if (event.key === "Enter" || event.key === "Next") {
										(event.target as HTMLInputElement).blur();
									}
								}}
								autoComplete="off"
								onChange={(event: any) => {
									dispatch(setCreateNewPropertyMinBathroomsThunk(Number(event.target.value.replace(/\D/g, ""))));
									if (
										(property_bathroom.max !== 0 &&
											Number(event.target.value.replace(/\D/g, "")) > property_bathroom.max) ||
										isNaN(Number(event.target.value.replace(/\D/g, "")))
									) {
										setErrorMinBathroom(true);
										setErrorMaxBathroom(true);
										setSubmitDisabled(true);
									} else {
										setErrorMinBathroom(false);
										setErrorMaxBathroom(false);
										setSubmitDisabled(false);
									}
								}}
								sx={{
									width: "50%",
									// [theme.breakpoints.down("sm")]: {
									// 	margin: "0rem",
									// 	minWidth: "100%",
									// },
									"& .MuiOutlinedInput-root": {
										borderRadius: "0.75rem",
									},
								}}
								error={errorMinBathroom}
								helperText={errorMinBathroom ? localLang.valueMoreThanMaxBathroom : ""}
								label={localLang.minBathroom}
							/>

							<TextField
								value={property_bathroom.max === 0 ? "" : property_bathroom.max}
								slotProps={{
									htmlInput: {
										min: 0,
										inputMode: "numeric",
										pattern: "[0-9]*",
									},
								}}
								onKeyUp={(event: React.KeyboardEvent<HTMLInputElement>) => {
									if (event.key === "Enter" || event.key === "Next") {
										(event.target as HTMLInputElement).blur();
									}
								}}
								autoComplete="off"
								onChange={(event: any) => {
									dispatch(setCreateNewPropertyMaxBathroomsThunk(Number(event.target.value.replace(/\D/g, ""))));
									if (
										(property_bathroom.min !== 0 &&
											Number(event.target.value.replace(/\D/g, "")) < property_bathroom.min) ||
										isNaN(Number(event.target.value.replace(/\D/g, "")))
									) {
										setErrorMinBathroom(true);
										setErrorMaxBathroom(true);
										setSubmitDisabled(true);
									} else {
										setErrorMinBathroom(false);
										setErrorMaxBathroom(false);
										setSubmitDisabled(false);
									}
								}}
								sx={{
									width: "50%",
									// [theme.breakpoints.down("sm")]: {
									// 	margin: "0rem",
									// 	minWidth: "100%",
									// },
									"& .MuiOutlinedInput-root": {
										borderRadius: "0.75rem",
									},
								}}
								error={errorMaxBathroom}
								helperText={errorMaxBathroom ? localLang.valuelessThanMinBathroom : ""}
								label={localLang.maxBathroom}
							/>
						</TextContainer>
					)}
				</TextContainer>
			) : null} */}

			{propertyData.transaction_type !== "ofb" &&
				propertyData.transaction_type !== "" &&
				propertyData.property_category !== "" &&
				propertyData.property_type.length !== 0 &&
				!propertyData.property_type.includes("Plot") &&
				!propertyData.property_type.includes("Farm Plot") &&
				!propertyData.property_type.includes("Plot (Commercial)") &&
				!propertyData.property_type.includes("Land (Residential)") &&
				!propertyData.property_type.includes("Land (Commercial)") &&
				!propertyData.property_type.includes("Land (Agricultural)") &&
				!propertyData.property_type.includes("Land (Industrial)") &&
				!propertyData.property_type.includes("Studio Apartment") &&
				!propertyData.property_type.includes("Building") &&
				!propertyData.property_type.includes("Shop") &&
				!propertyData.property_type.includes("Office Space") &&
				!propertyData.property_type.includes("Co-working") &&
				!propertyData.property_type.includes("Warehouse") &&
				!propertyData.property_type.includes("Factory") &&
				!propertyData.property_type.includes("Mining") && (
					<TextContainer id="property-bathroom">
						{(propertyData.transaction_type !== "buy" && !propertyData.property_type.includes("Co-living/PG")) ||
						propertyData.property_type.includes("Independent House") ? (
							<FormControl sx={{ width: "100%" }}>
								<Typography
									sx={{
										fontFamily: "Poppins",
										fontSize: "14px",
										fontWeight: 500,
										lineHeight: "157%",
										letterSpacing: "0.1px",
										mb: "10px",
									}}
								>
									{localLang.bathrooms}
								</Typography>

								<Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", gap: "0.5rem" }}>
									{["1", "2", "3", "4", "5+"].map((item) => {
										const val = item === "5+" ? 5 : parseInt(item);
										const isSelected = property_bathroom.max === val;

										return (
											<Chip
												key={item}
												label={item}
												clickable
												onClick={() => {
													dispatch(setCreateNewPropertyMinBathroomsThunk(val));
													dispatch(setCreateNewPropertyMaxBathroomsThunk(val));
													setErrorBathroom(false);
													setSubmitDisabled(false);
												}}
												sx={{
													textAlign: "center",
													borderRadius: "8px",
													border: isSelected
														? "1px solid var(--Primary-New-Dark, #B05911)"
														: "1px solid var(--Other-New-Divider, rgba(0, 0, 0, 0.12))",
													backgroundColor: isSelected ? "#FFEBDA" : "#fff",
													color: isSelected ? "#B05911" : "inherit",
													fontWeight: 500,
													fontSize: "14px",
													height: "40px",
													padding: "0.75rem",
												}}
											/>
										);
									})}
								</Box>

								{errorBathroom && <FormHelperText error>{localLang.validNumberError}</FormHelperText>}
							</FormControl>
						) : (
							<TextContainer sx={{ gap: "1.5rem", flexDirection: "column" }}>
								{/* Min Bathrooms */}
								<FormControl sx={{ width: "100%" }}>
									<Typography
										sx={{
											fontFamily: "Poppins",
											fontSize: "14px",
											fontWeight: 500,
											lineHeight: "157%",
											letterSpacing: "0.1px",
											mb: "10px",
										}}
									>
										{localLang.minBathroom}
									</Typography>
									<Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", gap: "0.5rem" }}>
										{["1", "2", "3", "4", "5+"].map((item) => {
											const val = item === "5+" ? 5 : parseInt(item);
											const isSelected = property_bathroom.min === val;

											return (
												<Chip
													key={item}
													label={item}
													clickable
													onClick={() => {
														dispatch(setCreateNewPropertyMinBathroomsThunk(val));
														if (property_bathroom.max !== 0 && val > property_bathroom.max) {
															setErrorMinBathroom(true);
															setErrorMaxBathroom(true);
															setSubmitDisabled(true);
														} else {
															setErrorMinBathroom(false);
															setErrorMaxBathroom(false);
															setSubmitDisabled(false);
														}
													}}
													sx={{
														textAlign: "center",
														borderRadius: "8px",
														border: isSelected
															? "1px solid var(--Primary-New-Dark, #B05911)"
															: "1px solid var(--Other-New-Divider, rgba(0, 0, 0, 0.12))",
														backgroundColor: isSelected ? "#FFEBDA" : "#fff",
														color: isSelected ? "#B05911" : "inherit",
														fontWeight: 400,
														fontSize: "14px",
														height: "40px",
														padding: "0.75rem",
													}}
												/>
											);
										})}
									</Box>
									{errorMinBathroom && <FormHelperText error>{localLang.valueMoreThanMaxBathroom}</FormHelperText>}
								</FormControl>

								{/* Max Bathrooms */}
								<FormControl sx={{ width: "100%" }}>
									<Typography
										sx={{
											fontFamily: "Poppins",
											fontSize: "14px",
											fontWeight: 500,
											lineHeight: "157%",
											letterSpacing: "0.1px",
											mb: "10px",
										}}
									>
										{localLang.maxBathroom}
									</Typography>
									<Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", gap: "0.5rem" }}>
										{["1", "2", "3", "4", "5+"].map((item) => {
											const val = item === "5+" ? 5 : parseInt(item);
											const isSelected = property_bathroom.max === val;

											return (
												<Chip
													key={item}
													label={item}
													clickable
													onClick={() => {
														dispatch(setCreateNewPropertyMaxBathroomsThunk(val));
														if (property_bathroom.min !== 0 && val < property_bathroom.min) {
															setErrorMinBathroom(true);
															setErrorMaxBathroom(true);
															setSubmitDisabled(true);
														} else {
															setErrorMinBathroom(false);
															setErrorMaxBathroom(false);
															setSubmitDisabled(false);
														}
													}}
													sx={{
														textAlign: "center",
														borderRadius: "8px",
														border: isSelected
															? "1px solid var(--Primary-New-Dark, #B05911)"
															: "1px solid var(--Other-New-Divider, rgba(0, 0, 0, 0.12))",
														backgroundColor: isSelected ? "#FFEBDA" : "#fff",
														color: isSelected ? "#B05911" : "inherit",
														fontWeight: 400,
														fontSize: "14px",
														height: "40px",
														padding: "0.75rem",
													}}
												/>
											);
										})}
									</Box>
									{errorMaxBathroom && <FormHelperText error>{localLang.valuelessThanMinBathroom}</FormHelperText>}
								</FormControl>
							</TextContainer>
						)}
					</TextContainer>
				)}

			{/* Balconies */}

			{propertyData.transaction_type !== "ofb" &&
			propertyData.transaction_type !== "" &&
			propertyData.property_category !== "" &&
			propertyData.property_type.length !== 0 &&
			!propertyData.property_type.includes("Co-living/PG") &&
			!propertyData.property_type.includes("Plot") &&
			!propertyData.property_type.includes("Farm Plot") &&
			!propertyData.property_type.includes("Plot (Commercial)") &&
			!propertyData.property_type.includes("Land (Residential)") &&
			!propertyData.property_type.includes("Land (Commercial)") &&
			!propertyData.property_type.includes("Land (Agricultural)") &&
			!propertyData.property_type.includes("Land (Industrial)") &&
			!propertyData.property_type.includes("Building") &&
			!propertyData.property_type.includes("Shop") &&
			!propertyData.property_type.includes("Office Space") &&
			!propertyData.property_type.includes("Co-working") &&
			!propertyData.property_type.includes("Warehouse") &&
			!propertyData.property_type.includes("Factory") &&
			!propertyData.property_type.includes("Mining") ? (
				<TextContainer
					sx={{
						display: "flex",
						flexDirection: "column",
						justifyContent: "flex-start",
						alignItems: "flex-start",
					}}
				>
					<Typography
						sx={{
							fontFamily: "Poppins",
							fontSize: "14px",
							fontWeight: 500,
							lineHeight: "157%",
							letterSpacing: "0.1px",
							mb: "10px",
						}}
					>
						{localLang.balconies}
					</Typography>
					{(propertyData.transaction_type !== "buy" && !propertyData.property_type.includes("Co-living/PG")) ||
					propertyData.property_type.includes("Independent House") ? (
						<TextField
							value={property_balconies.max === 0 ? "" : property_balconies.max}
							slotProps={{
								htmlInput: {
									min: 0,
									inputMode: "numeric",
									pattern: "[0-9]*",
								},
							}}
							onKeyUp={(event: React.KeyboardEvent<HTMLInputElement>) => {
								if (event.key === "Enter" || event.key === "Next") {
									(event.target as HTMLInputElement).blur();
								}
							}}
							sx={{
								width: "100%",
								"& .MuiOutlinedInput-root": {
									borderRadius: "0.75rem",
								},
							}}
							autoComplete="off"
							onChange={(event: any) => {
								dispatch(setCreateNePropertyMinBalconiesThunk(Number(event.target.value.replace(/\D/g, ""))));
								dispatch(setCreateNewPropertyMaxBalconiesThunk(Number(event.target.value.replace(/\D/g, ""))));
							}}
							// label={localLang.balconies}
						/>
					) : (
						<TextField
							value={property_balconies.max === 0 ? "" : property_balconies.max}
							slotProps={{
								htmlInput: {
									min: 0,
									inputMode: "numeric",
									pattern: "[0-9]*",
								},
							}}
							onKeyUp={(event: React.KeyboardEvent<HTMLInputElement>) => {
								if (event.key === "Enter" || event.key === "Next") {
									(event.target as HTMLInputElement).blur();
								}
							}}
							sx={{
								width: "100%",
								"& .MuiOutlinedInput-root": {
									borderRadius: "0.75rem",
								},
							}}
							autoComplete="off"
							onChange={(event: any) => {
								dispatch(setCreateNePropertyMinBalconiesThunk(Number(event.target.value.replace(/\D/g, ""))));
								dispatch(setCreateNewPropertyMaxBalconiesThunk(Number(event.target.value.replace(/\D/g, ""))));
							}}
							label={localLang.balconies}
						/>
					)}
				</TextContainer>
			) : null}

			{/* No of Seats */}

			{propertyData.transaction_type !== "ofb" &&
			propertyData.transaction_type !== "" &&
			propertyData.property_category !== "" &&
			propertyData.property_type.length !== 0 &&
			propertyData.property_type.includes("Co-working") ? (
				<TextContainer
					sx={{
						display: "flex",
						flexDirection: "column",
						justifyContent: "flex-start",
						alignItems: "flex-start",
					}}
				>
					<Typography
						sx={{
							fontFamily: "Poppins",
							fontSize: "14px",
							fontWeight: 500,
							lineHeight: "157%",
							letterSpacing: "0.1px",
							mb: "10px",
						}}
					>
						{localLang.noOfSeats}
					</Typography>
					<TextField
						id="property-no-of-seats"
						value={property_no_of_seats.max === 0 ? "" : property_no_of_seats.max}
						slotProps={{
							htmlInput: {
								min: 0,
								inputMode: "numeric",
								pattern: "[0-9]*",
							},
						}}
						onKeyUp={(event: React.KeyboardEvent<HTMLInputElement>) => {
							if (event.key === "Enter" || event.key === "Next") {
								(event.target as HTMLInputElement).blur();
							}
						}}
						sx={{
							width: "100%",
							"& .MuiOutlinedInput-root": {
								borderRadius: "0.75rem",
							},
						}}
						autoComplete="off"
						onChange={(event: any) => {
							dispatch(setCreateNewPropertyNoOfSeatsThunk(Number(event.target.value.replace(/\D/g, ""))));
							if (isNaN(Number(event.target.value.replace(/\D/g, "")))) {
								setErrorNoOfSeats(true);
								setSubmitDisabled(true);
							} else {
								setErrorNoOfSeats(false);
								setSubmitDisabled(false);
								setPropertyNoOfSeatsError(false);
							}
						}}
						error={errorNoOfSeats || propertyNoOfSeatsError}
						helperText={errorNoOfSeats || propertyNoOfSeatsError ? localLang.validNumberError : ""}
						label={localLang.noOfSeats}
					/>
				</TextContainer>
			) : null}

			{/* No of Units */}

			{propertyData.transaction_type !== "" &&
			propertyData.property_category !== "" &&
			propertyData.property_type.length !== 0 &&
			propertyData.transaction_type == "buy" &&
			(propertyData.property_type.includes("Apartment") ||
				propertyData.property_type.includes("Plot") ||
				propertyData.property_type.includes("Farm Plot") ||
				propertyData.property_type.includes("Plot (Commercial)") ||
				propertyData.property_type.includes("Villa") ||
				propertyData.property_type.includes("Row House") ||
				propertyData.property_type.includes("Builder Floor Apartment") ||
				propertyData.property_type.includes("Studio Apartment") ||
				propertyData.property_type.includes("Penthouse")) ? (
				<TextContainer
					sx={{
						display: "flex",
						flexDirection: "column",
						justifyContent: "flex-start",
						alignItems: "flex-start",
					}}
				>
					<Typography
						sx={{
							fontFamily: "Poppins",
							fontSize: "14px",
							fontWeight: 500,
							lineHeight: "157%",
							letterSpacing: "0.1px",
							mb: "10px",
						}}
					>
						{localLang.totalNoOfUnits}
					</Typography>
					<TextField
						id="property-no-of-units"
						value={property_no_of_units.max === 0 ? "" : property_no_of_units.max}
						slotProps={{
							htmlInput: {
								min: 0,
								inputMode: "numeric",
								pattern: "[0-9]*",
							},
						}}
						onKeyUp={(event: React.KeyboardEvent<HTMLInputElement>) => {
							if (event.key === "Enter" || event.key === "Next") {
								(event.target as HTMLInputElement).blur();
							}
						}}
						autoComplete="off"
						sx={{
							width: "100%",
							"& .MuiOutlinedInput-root": {
								borderRadius: "0.75rem",
							},
						}}
						onChange={(event: any) => {
							dispatch(setCreateNewPropertyNoOfUnitsThunk(Number(event.target.value.replace(/\D/g, ""))));
							if (isNaN(Number(event.target.value.replace(/\D/g, "")))) {
								setErrorNoOfUnits(true);
								setSubmitDisabled(true);
							} else {
								setErrorNoOfUnits(false);
								setSubmitDisabled(false);
								setPropertyNoOfUnitsError(false);
							}
						}}
						error={errorNoOfUnits || propertyNoOfUnitsError}
						helperText={errorNoOfUnits || propertyNoOfUnitsError ? localLang.validNumberError : ""}
						label={localLang.totalNoOfUnits}
					/>
				</TextContainer>
			) : null}

			{/* No of Floors */}

			{propertyData.transaction_type !== "ofb" &&
			propertyData.transaction_type !== "" &&
			propertyData.property_category !== "" &&
			propertyData.property_type.length !== 0 &&
			!propertyData.property_type.includes("Plot") &&
			!propertyData.property_type.includes("Farm Plot") &&
			!propertyData.property_type.includes("Plot (Commercial)") &&
			!propertyData.property_type.includes("Land (Residential)") &&
			!propertyData.property_type.includes("Land (Commercial)") &&
			!propertyData.property_type.includes("Land (Agricultural)") &&
			!propertyData.property_type.includes("Land (Industrial)") &&
			!propertyData.property_type.includes("Warehouse") &&
			!propertyData.property_type.includes("Factory") &&
			!propertyData.property_type.includes("Mining") ? (
				<TextContainer
					sx={{
						display: "flex",
						flexDirection: "column",
						justifyContent: "flex-start",
						alignItems: "flex-start",
					}}
				>
					<Typography
						sx={{
							fontFamily: "Poppins",
							fontSize: "14px",
							fontWeight: 500,
							lineHeight: "157%",
							letterSpacing: "0.1px",
							mb: "10px",
						}}
					>
						{localLang.totalNoOfFloors}
					</Typography>
					<TextField
						value={property_floors.max === 0 ? "" : property_floors.max}
						slotProps={{
							htmlInput: {
								min: 0,
								inputMode: "numeric",
								pattern: "[0-9]*",
							},
						}}
						onKeyUp={(event: React.KeyboardEvent<HTMLInputElement>) => {
							if (event.key === "Enter" || event.key === "Next") {
								(event.target as HTMLInputElement).blur();
							}
						}}
						sx={{
							width: "100%",
							"& .MuiOutlinedInput-root": {
								borderRadius: "0.75rem",
							},
						}}
						onChange={(event: any) => {
							// setMinFloors(event.target.value);
							dispatch(setCreateNewPropertyFloorNoThunk(Number(event.target.value.replace(/\D/g, ""))));
							dispatch(setCreateNewPropertyMaxFloorsThunk(Number(event.target.value.replace(/\D/g, ""))));
							if (isNaN(Number(event.target.value.replace(/\D/g, "")))) {
								setErrorMaxFloors(true);
								setSubmitDisabled(true);
							} else {
								setErrorMaxFloors(false);
								setSubmitDisabled(false);
							}
						}}
						error={errorMaxFloors}
						helperText={errorMaxFloors ? localLang.validNumberError : ""}
						label={localLang.totalNoOfFloors}
						autoComplete="off"
					/>
				</TextContainer>
			) : null}

			{/* Plot Dimensions */}

			{propertyData.transaction_type !== "ofb" &&
			propertyData.transaction_type !== "" &&
			propertyData.property_category !== "" &&
			propertyData.property_type.length !== 0 &&
			(propertyData.property_type.includes("Plot") ||
				propertyData.property_type.includes("Farm Plot") ||
				propertyData.property_type.includes("Plot (Commercial)")) ? (
				<FormControl
					id="property-plot-dimensions"
					sx={{
						width: "100%",
						margin: "0rem 0rem 0rem 0rem",
						[theme.breakpoints.down("sm")]: {
							margin: "0.5rem 0rem 0rem 0.5rem",
						},
						"& .MuiOutlinedInput-root": {
							borderRadius: "0.75rem",
						},
					}}
					error={propertyPlotDimensionsError}
				>
					<InputLabel id="demo-simple-select-helper-label">{localLang.plotDimention}</InputLabel>
					<Select
						labelId="demo-simple-select-helper-label"
						id="demo-simple-select-helper"
						multiple={propertyData.transaction_type === "buy" ? true : false}
						value={property_plot_dimensions}
						sx={{
							width: "100%",
							[theme.breakpoints.down("sm")]: {
								minWidth: "100%",
							},
							"& .MuiOutlinedInput-root": {
								borderRadius: "0.75rem",
							},
						}}
						MenuProps={{
							PaperProps: {
								sx: {
									"& .MuiMenu-list": {
										direction: router.locale === "ar-AE" ? "rtl" : "ltr",
									},
								},
							},
						}}
						label={localLang.plotDimention}
						onChange={(event: any) => {
							setPropertyPlotDimensionsError(false);
							dispatch(
								setCreateNewPropertyPlotDimensionsThunk(
									typeof event.target.value === "string" ? event.target.value.split(",") : event.target.value,
								),
							);
							if (propertyData.transaction_type !== "buy") {
								if (event.target.value !== "Other") {
									dispatch(
										setCreateNewPropertyMinSuperBuiltUpAreaThunk(
											Number(event.target.value.split(" x ")[0] * event.target.value.split(" x ")[1]),
										),
									);
									dispatch(
										setCreateNewPropertyMaxSuperBuiltUpAreaThunk(
											Number(event.target.value.split(" x ")[0] * event.target.value.split(" x ")[1]),
										),
									);
								} else {
									dispatch(setCreateNewPropertyMinSuperBuiltUpAreaThunk(0));
									dispatch(setCreateNewPropertyMaxSuperBuiltUpAreaThunk(0));
								}
							}
						}}
						input={<OutlinedInput label="Plot Dimensions" />}
						renderValue={(selected) =>
							propertyData.transaction_type !== "buy" && selected[0] !== "Other" ? selected : selected.join(", ")
						}
					>
						{plot_dimensions?.map((name) => (
							<MenuItem
								key={name}
								value={name}
							>
								{propertyData.transaction_type === "buy" ? (
									<Checkbox checked={property_plot_dimensions.indexOf(name) > -1} />
								) : null}

								<ListItemText primary={name} />
							</MenuItem>
						))}
					</Select>
					<FormHelperText>{propertyPlotDimensionsError ? localLang.selectValidplotDimention : ""}</FormHelperText>
				</FormControl>
			) : null}

			{/* Other Plot Dimensions */}

			{propertyData.transaction_type !== "" &&
			propertyData.property_category !== "" &&
			propertyData.property_type.length !== 0 &&
			(propertyData.property_type.includes("Plot") ||
				propertyData.property_type.includes("Farm Plot") ||
				propertyData.property_type.includes("Plot (Commercial)")) &&
			property_plot_dimensions.includes("Other") ? (
				// <TextContainer sx={{ alignItems: "flex-start" }}>
				<TextContainer
					sx={{
						width: "100%",
						flexDirection: "column",
						justifyContent: "flex-start",
						alignItems: "flex-start",
						gap: "0.5rem",
						[theme.breakpoints.down("sm")]: {
							margin: "0rem 0rem 0rem 0.5rem",
						},
					}}
				>
					<Text
						sx={{
							fontFamily: "Poppins",
							fontSize: "14px",
							fontWeight: 500,
							lineHeight: "157%",
							letterSpacing: "0.1px",
							mb: "10px",
						}}
					>
						{localLang.otherPlotDimentions}
					</Text>

					{property_plot_dimensions.filter((item) => !plot_dimensions.includes(item)).length > 0
						? property_plot_dimensions
								.filter((item) => !plot_dimensions.includes(item))
								.map((item: string, index: number) => (
									<div key={index}>
										<TextContainer sx={{ justifyContent: "space-between", alignItems: "flex-start", gap: "1rem" }}>
											<TextField
												value={item.split(" x ")[0] === "0" ? "" : item.split(" x ")[0]}
												onChange={(event: any) => {
													const { value } = event.target;
													const other_plot_dimensions = property_plot_dimensions.filter(
														(item) => !plot_dimensions.includes(item),
													);
													const list = [...other_plot_dimensions];
													const dimensions = list[index].split(" x ");
													dimensions[0] = Number(value.replace(/\D/g, "")).toString();
													list[index] = dimensions.join(" x ");
													dispatch(
														setCreateNewPropertyPlotDimensionsThunk([
															...property_plot_dimensions.filter((item) => plot_dimensions.includes(item)),
															...list,
														]),
													);
													if (propertyData.transaction_type !== "buy") {
														dispatch(
															setCreateNewPropertyMinSuperBuiltUpAreaThunk(
																parseInt(value) * (dimensions[1] && dimensions[1] !== "" ? parseInt(dimensions[1]) : 0),
															),
														);
														dispatch(
															setCreateNewPropertyMaxSuperBuiltUpAreaThunk(
																parseInt(value) * (dimensions[1] && dimensions[1] !== "" ? parseInt(dimensions[1]) : 0),
															),
														);
													}
													const newValue = Number(value.replace(/\D/g, ""));
													const newErrorMinOtherPlot = [...errorMinOtherPlot];
													if (isNaN(newValue) || newValue <= 0) {
														newErrorMinOtherPlot[index] = true;
														setSubmitDisabled(true);
													} else {
														newErrorMinOtherPlot[index] = false;
														setSubmitDisabled(false);
													}
													setErrorMinOtherPlot(newErrorMinOtherPlot);
												}}
												label={localLang.length}
												sx={{
													width: "45%",
													"& .MuiOutlinedInput-root": {
														borderRadius: "0.75rem",
													},
												}}
												autoComplete="off"
												error={errorMinOtherPlot[index]}
												helperText={errorMinOtherPlot[index] ? localLang.invalidNumber : ""}
												slotProps={{
													htmlInput: {
														inputMode: "numeric",
														pattern: "[0-9]*",
													},
												}}
												onKeyUp={(event: React.KeyboardEvent<HTMLInputElement>) => {
													if (event.key === "Enter" || event.key === "Next") {
														(event.target as HTMLInputElement).blur();
													}
												}}
											/>

											<Typography
												variant="body1"
												sx={{ margin: "1rem 0rem 0rem 0rem" }}
											>
												x
											</Typography>

											<TextField
												value={item.split(" x ")[1] === "0" ? "" : item.split(" x ")[1]}
												slotProps={{
													htmlInput: {
														min: 0,
														inputMode: "numeric",
														pattern: "[0-9]*",
													},
												}}
												onKeyUp={(event: React.KeyboardEvent<HTMLInputElement>) => {
													if (event.key === "Enter" || event.key === "Next") {
														(event.target as HTMLInputElement).blur();
													}
												}}
												onChange={(event: any) => {
													const { value } = event.target;
													const other_plot_dimensions = property_plot_dimensions.filter(
														(item) => !plot_dimensions.includes(item),
													);
													const list = [...other_plot_dimensions];
													const dimensions = list[index].split(" x ");
													dimensions[1] = Number(value.replace(/\D/g, "")).toString();
													list[index] = dimensions.join(" x ");
													dispatch(
														setCreateNewPropertyPlotDimensionsThunk([
															...property_plot_dimensions.filter((item) => plot_dimensions.includes(item)),
															...list,
														]),
													);
													if (propertyData.transaction_type !== "buy") {
														dispatch(
															setCreateNewPropertyMinSuperBuiltUpAreaThunk(
																parseInt(value) * (dimensions[0] && dimensions[0] !== "" ? parseInt(dimensions[0]) : 0),
															),
														);
														dispatch(
															setCreateNewPropertyMaxSuperBuiltUpAreaThunk(
																parseInt(value) * (dimensions[0] && dimensions[0] !== "" ? parseInt(dimensions[0]) : 0),
															),
														);
													}
													const newValue = Number(value.replace(/\D/g, ""));
													const newErrorMaxOtherPlot = [...errorMaxOtherPlot];
													if (isNaN(newValue) || newValue <= 0) {
														newErrorMaxOtherPlot[index] = true;
														setSubmitDisabled(true);
													} else {
														newErrorMaxOtherPlot[index] = false;
														setSubmitDisabled(false);
													}
													setErrorMaxOtherPlot(newErrorMaxOtherPlot);
												}}
												label={localLang.width}
												sx={{
													width: "45%",
												}}
												autoComplete="off"
												error={errorMaxOtherPlot[index]}
												helperText={errorMaxOtherPlot[index] ? localLang.invalidNumber : ""}
											/>
											{propertyData.transaction_type === "buy" ? (
												<IconButton
													color="primary"
													sx={{ margin: "0.5rem 0rem 0rem 0rem" }}
													onClick={() => {
														const other_plot_dimensions = property_plot_dimensions.filter(
															(item) => !plot_dimensions.includes(item),
														);
														const list = [...other_plot_dimensions];
														list.splice(index, 1);
														dispatch(
															setCreateNewPropertyPlotDimensionsThunk([
																...property_plot_dimensions.filter((item) => plot_dimensions.includes(item)),
																...list,
															]),
														);
													}}
												>
													<DeleteOutline />
												</IconButton>
											) : null}
										</TextContainer>

										{property_plot_dimensions.filter((item) => !plot_dimensions.includes(item)).length - 1 === index &&
										propertyData.transaction_type === "buy" ? (
											<IconButton
												color="primary"
												onClick={() => {
													dispatch(setCreateNewPropertyPlotDimensionsThunk([...property_plot_dimensions, ""]));
												}}
												sx={{
													fontSize: "0.875rem",
													borderRadius: "0.5rem",
												}}
											>
												<Add sx={{ margin: "0rem 0.25rem 0rem 0rem" }} />
												{localLang.addMore}
											</IconButton>
										) : null}
									</div>
								))
						: dispatch(setCreateNewPropertyPlotDimensionsThunk([...property_plot_dimensions, ""]))}
				</TextContainer>
			) : // </TextContainer>
			null}

			{/* Total land Area */}

			{propertyData.transaction_type !== "" &&
			propertyData.property_category !== "" &&
			propertyData.property_type.length !== 0 &&
			((propertyData.transaction_type === "rent" &&
				(propertyData.property_type.includes("Land (Residential)") ||
					propertyData.property_type.includes("Land (Commercial)") ||
					propertyData.property_type.includes("Land (Agricultural)") ||
					propertyData.property_type.includes("Land (Industrial)") ||
					propertyData.property_type.includes("Mining"))) ||
				propertyData.transaction_type == "buy") ? (
				<TextContainer
					id="property-land-area"
					sx={{ alignItems: "flex-start" }}
				>
					<TextContainer
						sx={{
							width: "100%",
							flexDirection: "column",
							justifyContent: "flex-start",
							alignItems: "flex-start",
							gap: "0.5rem",
							// [theme.breakpoints.down("sm")]: {
							// 	margin: "0rem 0rem 0rem 0.5rem",
							// },
						}}
					>
						<Text
							sx={{
								[theme.breakpoints.down("sm")]: {
									margin: "0.3rem 0rem 0.5rem 0rem",
								},
								fontFamily: "Poppins",
								fontSize: "14px",
								fontWeight: 500,
								lineHeight: "157%",
								letterSpacing: "0.1px",
								mb: "10px",
							}}
						>
							{localLang.totalLandArea}
						</Text>
						<Typography
							sx={{
								fontSize: "0.875rem",
								fontWeight: 400,
								lineHeight: "143%",
								letterSpacing: "0.009rem",
								color: theme.palette.mode === "light" ? "#666666" : "#ffffff",
							}}
						>
							{localLang.totalLandAreaConfirmation}
						</Typography>
						<TextContainer sx={{ flexDirection: "row", alignItems: "flex-start", gap: "0.5rem", marginTop: "0.5rem" }}>
							<TextField
								value={
									property_area.land_area.max.acre === 0 || isNaN(property_area.land_area.max.acre)
										? ""
										: property_area.land_area.max.acre
								}
								sx={{
									width: "100%",
									"& .MuiOutlinedInput-root": {
										borderRadius: "0.75rem",
									},
								}}
								autoComplete="off"
								slotProps={{
									htmlInput: {
										min: 0,
										inputMode: "numeric",
										pattern: "[0-9]*",
									},
								}}
								onKeyUp={(event: React.KeyboardEvent<HTMLInputElement>) => {
									if (event.key === "Enter" || event.key === "Next") {
										(event.target as HTMLInputElement).blur();
									}
								}}
								onChange={(event: any) => {
									dispatch(setCreateNewPropertyLandAreaThunk(parseInt(event.target.value.replace(/\D/g, ""))));
									if (
										(propertyData.transaction_type == "resale" ||
											propertyData.transaction_type == "buy" ||
											(propertyData.transaction_type == "rent" &&
												(propertyData.property_type.includes("Land (Residential)") ||
													propertyData.property_type.includes("Land (Commercial)") ||
													propertyData.property_type.includes("Land (Agricultural)") ||
													propertyData.property_type.includes("Land (Industrial)") ||
													propertyData.property_type.includes("Plot") ||
													propertyData.property_type.includes("Farm Plot") ||
													propertyData.property_type.includes("Plot (Commercial)")))) &&
										isNaN(
											// Number(
											event.target.value.replace(/[^0-9.]/g, ""),
											// )
										)
									) {
										setErrorLandArea(true);
										setSubmitDisabled(true);
									} else {
										setErrorLandArea(false);
										setSubmitDisabled(false);
										setPropertyLandAreaError(false);
									}
								}}
								error={errorLandArea || propertyLandAreaError}
								helperText={errorLandArea || propertyLandAreaError ? localLang.validNumberError : ""}
								label={
									propertyData.transaction_type == "buy" ||
									(propertyData.transaction_type == "rent" &&
										(propertyData.property_type.includes("Land (Residential)") ||
											propertyData.property_type.includes("Land (Commercial)") ||
											propertyData.property_type.includes("Land (Agricultural)") ||
											propertyData.property_type.includes("Land (Industrial)") ||
											propertyData.property_type.includes("Mining")))
										? localLang.landArea + "*"
										: localLang.landArea
								}
							/>

							<FormControl
								sx={{
									width: "100%",
									"& .MuiOutlinedInput-root": {
										borderRadius: "0.75rem",
									},
								}}
							>
								<InputLabel id="demo-simple-select-helper-label">{localLang.unit}</InputLabel>
								<Select
									labelId="demo-simple-select-helper-label"
									id="demo-simple-select-helper"
									value={landAreaUnit}
									sx={{
										width: "100%",
										height: "3.5rem",
										"& .MuiOutlinedInput-root": {
											borderRadius: "0.75rem",
										},
									}}
									MenuProps={{
										PaperProps: {
											sx: {
												"& .MuiMenu-list": {
													direction: router.locale === "ar-AE" ? "rtl" : "ltr",
												},
											},
										},
									}}
									label={localLang.unit}
									onChange={(event: any) => {
										setLandAreaUnit(event.target.value);
										dispatch(setCreateNewPropertyLandAreaUnitThunk(event.target.value));
									}}
								>
									<MenuItem value="acre">{localLang.acre}</MenuItem>
									<MenuItem value="sqft">{localLang.sqft}</MenuItem>
									<MenuItem value="sqmt">{localLang.sqm}</MenuItem>
								</Select>
							</FormControl>
						</TextContainer>
					</TextContainer>
				</TextContainer>
			) : null}

			{/* Super Built Up Area */}

			{propertyData.transaction_type !== "ofb" &&
			propertyData.transaction_type !== "" &&
			propertyData.property_category !== "" &&
			propertyData.property_type.length !== 0 &&
			!propertyData.property_type.includes("Land (Residential)") &&
			!propertyData.property_type.includes("Land (Commercial)") &&
			!propertyData.property_type.includes("Land (Agricultural)") &&
			!propertyData.property_type.includes("Land (Industrial)") &&
			!propertyData.property_type.includes("Mining") ? (
				<TextContainer
					id="property-super-built-up-area"
					sx={{ alignItems: "flex-start" }}
				>
					{(propertyData.transaction_type == "buy" && propertyData.property_type.includes("Independent House")) ||
					(propertyData.transaction_type == "buy" && propertyData.property_type.includes("Co-living/PG")) ||
					(propertyData.transaction_type == "buy" && propertyData.property_type.includes("Building")) ||
					(propertyData.transaction_type == "buy" && propertyData.property_type.includes("Shop")) ||
					(propertyData.transaction_type == "buy" && propertyData.property_type.includes("Office Space")) ||
					(propertyData.transaction_type == "buy" && propertyData.property_type.includes("Co-working")) ||
					(propertyData.transaction_type == "buy" && propertyData.property_type.includes("Warehouse")) ||
					(propertyData.transaction_type == "buy" && propertyData.property_type.includes("Factory")) ||
					(propertyData.transaction_type == "rent" && propertyData.property_type.includes("Independent House")) ||
					(propertyData.transaction_type == "rent" && propertyData.property_type.includes("Building")) ||
					(propertyData.transaction_type == "rent" && propertyData.property_type.includes("Shop")) ||
					(propertyData.transaction_type == "rent" && propertyData.property_type.includes("Apartment")) ||
					(propertyData.transaction_type == "rent" && propertyData.property_type.includes("Villa")) ||
					(propertyData.transaction_type == "rent" && propertyData.property_type.includes("Builder Floor Apartment")) ||
					(propertyData.transaction_type == "rent" && propertyData.property_type.includes("Penthouse")) ||
					(propertyData.transaction_type == "rent" && propertyData.property_type.includes("Row House")) ||
					(propertyData.transaction_type == "rent" && propertyData.property_type.includes("Plot")) ||
					(propertyData.transaction_type == "rent" && propertyData.property_type.includes("Farm Plot")) ||
					(propertyData.transaction_type == "rent" && propertyData.property_type.includes("Plot (Commercial)")) ||
					(propertyData.transaction_type == "rent" && propertyData.property_type.includes("Farm House")) ||
					(propertyData.transaction_type == "rent" && propertyData.property_type.includes("Warehouse")) ||
					(propertyData.transaction_type == "rent" && propertyData.property_type.includes("Factory")) ||
					(propertyData.transaction_type == "resale" && propertyData.property_type.includes("Independent House")) ||
					(propertyData.transaction_type == "resale" && propertyData.property_type.includes("Co-living/PG")) ||
					(propertyData.transaction_type == "resale" && propertyData.property_type.includes("Building")) ||
					(propertyData.transaction_type == "resale" && propertyData.property_type.includes("Shop")) ||
					(propertyData.transaction_type == "resale" && propertyData.property_type.includes("Office Space")) ||
					(propertyData.transaction_type == "resale" && propertyData.property_type.includes("Co-working")) ||
					(propertyData.transaction_type == "resale" && propertyData.property_type.includes("Plot")) ||
					(propertyData.transaction_type == "resale" && propertyData.property_type.includes("Farm Plot")) ||
					(propertyData.transaction_type == "resale" && propertyData.property_type.includes("Plot (Commercial)")) ||
					(propertyData.transaction_type == "resale" && propertyData.property_type.includes("Farm House")) ||
					(propertyData.transaction_type == "resale" && propertyData.property_type.includes("Warehouse")) ||
					(propertyData.transaction_type == "resale" && propertyData.property_type.includes("Apartment")) ||
					(propertyData.transaction_type == "resale" && propertyData.property_type.includes("Villa")) ||
					(propertyData.transaction_type == "resale" &&
						propertyData.property_type.includes("Builder Floor Apartment")) ||
					(propertyData.transaction_type == "resale" && propertyData.property_type.includes("Penthouse")) ||
					(propertyData.transaction_type == "resale" && propertyData.property_type.includes("Row House")) ||
					(propertyData.transaction_type == "resale" && propertyData.property_type.includes("Factory")) ? (
						<TextContainer
							sx={{
								width: "100%",
								flexDirection: "column",
								justifyContent: "flex-start",
								alignItems: "flex-start",
								gap: "0.5rem",
								// [theme.breakpoints.down("sm")]: {
								// 	margin: "0rem 0rem 0rem 0.5rem",
								// },
							}}
						>
							<Text
								sx={{
									// margin: "0.35rem 0rem 0.5rem 0rem",
									[theme.breakpoints.down("sm")]: {
										margin: "0.3rem 0rem 0.5rem 0rem",
									},
									fontFamily: "Poppins",
									fontSize: "14px",
									fontWeight: 500,
									lineHeight: "157%",
									letterSpacing: "0.1px",
									mb: "10px",
								}}
							>
								{propertyData.property_type.includes("Plot") ||
								propertyData.property_type.includes("Farm Plot") ||
								propertyData.property_type.includes("Plot (Commercial)")
									? localLang.plotArea
									: localLang.superBuiltUpArea}
							</Text>
							{propertyData.property_type.includes("Plot") ||
							propertyData.property_type.includes("Farm Plot") ||
							propertyData.property_type.includes("Plot (Commercial)") ? null : (
								<Typography
									sx={{
										fontSize: "0.875rem",
										fontWeight: 400,
										lineHeight: "143%",
										letterSpacing: "0.009rem",
										color: theme.palette.mode === "light" ? "#666666" : "#ffffff",
									}}
								>
									{localLang.SBUAInfo}
								</Typography>
							)}
							<TextContainer sx={{ flexDirection: "row", alignItems: "flex-start", gap: "1rem", marginTop: "0.5rem" }}>
								<TextField
									value={
										property_area.super_built_up_area.max.ft === 0 || isNaN(property_area.super_built_up_area.max.ft)
											? ""
											: property_area.super_built_up_area.max.ft
									}
									slotProps={{
										htmlInput: {
											min: 0,
											inputMode: "numeric",
											pattern: "[0-9]*",
										},
									}}
									onKeyUp={(event: React.KeyboardEvent<HTMLInputElement>) => {
										if (event.key === "Enter" || event.key === "Next") {
											(event.target as HTMLInputElement).blur();
										}
									}}
									sx={{
										width: "100%",
										"& .MuiOutlinedInput-root": {
											borderRadius: "0.75rem",
										},
									}}
									onChange={(event: any) => {
										dispatch(
											setCreateNewPropertyMinSuperBuiltUpAreaThunk(Number(event.target.value.replace(/\D/g, ""))),
										);
										dispatch(
											setCreateNewPropertyMaxSuperBuiltUpAreaThunk(Number(event.target.value.replace(/\D/g, ""))),
										);
										if (isNaN(Number(event.target.value.replace(/\D/g, "")))) {
											setErrorSba(true);
											setSubmitDisabled(true);
										} else {
											setErrorSba(false);
											setSubmitDisabled(false);
											setPropertySuperBuiltUpMaxAreaError(false);
										}
									}}
									error={errorSba || propertySuperBuiltUpMaxAreaError}
									helperText={errorSba || propertySuperBuiltUpMaxAreaError ? localLang.validNumberError : ""}
									label={localLang.area}
								/>
								<FormControl
									sx={{
										width: "100%",
										"& .MuiOutlinedInput-root": {
											borderRadius: "0.75rem",
										},
									}}
								>
									<InputLabel id="demo-simple-select-helper-label">Unit</InputLabel>
									<Select
										labelId="demo-simple-select-helper-label"
										id="demo-simple-select-helper"
										value={superBuiltUpAreaUnit}
										sx={{
											width: "100%",
											height: "3.5rem",
											"& .MuiOutlinedInput-root": {
												borderRadius: "0.75rem",
											},
										}}
										MenuProps={{
											PaperProps: {
												sx: {
													"& .MuiMenu-list": {
														direction: router.locale === "ar-AE" ? "rtl" : "ltr",
													},
												},
											},
										}}
										label={localLang.unit}
										onChange={(event: any) => {
											setSuperBuiltUpAreaUnit(event.target.value);
											setCarpetAreaUnit(event.target.value);
											dispatch(setCreateNewPropertySuperBuiltUpAreaUnitThunk(event.target.value));
										}}
									>
										<MenuItem value="sqft">{localLang.sqft}</MenuItem>
										<MenuItem value="sqmt">{localLang.sqm}</MenuItem>
									</Select>
								</FormControl>
							</TextContainer>
						</TextContainer>
					) : (
						<TextContainer
							sx={{
								width: "100%",
								flexDirection: "column",
								justifyContent: "flex-start",
								alignItems: "flex-start",
								gap: "0.5rem",
								// [theme.breakpoints.down("sm")]: {
								// 	margin: "0rem 0rem 0rem 0.5rem",
								// },
							}}
						>
							<Text
								sx={{
									margin: "0.35rem 0rem 0.5rem 0rem",
									fontFamily: "Poppins",
									fontSize: "14px",
									fontWeight: 500,
									lineHeight: "157%",
									letterSpacing: "0.1px",
									// mb: "10px",
									[theme.breakpoints.down("sm")]: {
										margin: "0.3rem 0rem 0.5rem 0rem",
									},
								}}
							>
								{propertyData.property_type.includes("Plot") ||
								propertyData.property_type.includes("Farm Plot") ||
								propertyData.property_type.includes("Plot (Commercial)")
									? localLang.plotArea
									: localLang.superBuiltUpArea}
							</Text>
							{propertyData.property_type.includes("Plot") ||
							propertyData.property_type.includes("Farm Plot") ||
							propertyData.property_type.includes("Plot (Commercial)") ? null : (
								<Typography
									sx={{
										fontSize: "0.875rem",
										fontWeight: 400,
										lineHeight: "143%",
										letterSpacing: "0.009rem",
										color: theme.palette.mode === "light" ? "#666666" : "#ffffff",
									}}
								>
									{localLang.SBUAInfo}
								</Typography>
							)}
							<TextContainer
								sx={{
									flexDirection: "column",
									alignItems: "flex-start",
									gap: "1rem",
									marginTop: "0.5rem",
									// [theme.breakpoints.down("sm")]: {
									// 	flexDirection: "column",
									// },
								}}
							>
								<TextContainer
									sx={{
										flexDirection: "row",
										alignItems: "flex-start",
										gap: "0.5rem",
										// [theme.breakpoints.down("sm")]: {
										// 	gap: "0.5rem",
										// },
									}}
								>
									<TextField
										value={
											property_area.super_built_up_area.min.ft === 0 || isNaN(property_area.super_built_up_area.min.ft)
												? ""
												: property_area.super_built_up_area.min.ft
										}
										slotProps={{
											htmlInput: {
												min: 0,
												inputMode: "numeric",
												pattern: "[0-9]*",
											},
										}}
										onKeyUp={(event: React.KeyboardEvent<HTMLInputElement>) => {
											if (event.key === "Enter" || event.key === "Next") {
												(event.target as HTMLInputElement).blur();
											}
										}}
										sx={{
											width: "50%",
											"& .MuiOutlinedInput-root": {
												borderRadius: "0.75rem",
											},
										}}
										autoComplete="off"
										onChange={(event: any) => {
											dispatch(
												setCreateNewPropertyMinSuperBuiltUpAreaThunk(Number(event.target.value.replace(/\D/g, ""))),
											);
											if (
												(property_area.super_built_up_area.max.ft !== 0 &&
													Number(event.target.value.replace(/\D/g, "")) > property_area.super_built_up_area.max.ft) ||
												isNaN(Number(event.target.value.replace(/\D/g, "")))
											) {
												setErrorMinSba(true);
												setErrorMaxSba(true);
												setSubmitDisabled(true);
											} else {
												setErrorMinSba(false);
												setErrorMaxSba(false);
												setSubmitDisabled(false);
												setPropertySuperBuiltUpMinAreaError(false);
											}
										}}
										error={errorMinSba || propertySuperBuiltUpMinAreaError}
										helperText={errorMinSba || propertySuperBuiltUpMinAreaError ? localLang.valueMoreThanMaxArea : ""}
										label={localLang.minArea}
									/>

									<TextField
										value={
											property_area.super_built_up_area.max.ft === 0 || isNaN(property_area.super_built_up_area.max.ft)
												? ""
												: property_area.super_built_up_area.max.ft
										}
										slotProps={{
											htmlInput: {
												min: 0,
												inputMode: "numeric",
												pattern: "[0-9]*",
											},
										}}
										onKeyUp={(event: React.KeyboardEvent<HTMLInputElement>) => {
											if (event.key === "Enter" || event.key === "Next") {
												(event.target as HTMLInputElement).blur();
											}
										}}
										sx={{
											width: "50%",
											"& .MuiOutlinedInput-root": {
												borderRadius: "0.75rem",
											},
										}}
										autoComplete="off"
										onChange={(event: any) => {
											dispatch(
												setCreateNewPropertyMaxSuperBuiltUpAreaThunk(Number(event.target.value.replace(/\D/g, ""))),
											);
											if (
												(property_area.super_built_up_area.min.ft !== 0 &&
													Number(event.target.value.replace(/\D/g, "")) < property_area.super_built_up_area.min.ft) ||
												isNaN(Number(event.target.value.replace(/\D/g, "")))
											) {
												setErrorMinSba(true);
												setErrorMaxSba(true);
												setSubmitDisabled(true);
											} else {
												setErrorMinSba(false);
												setErrorMaxSba(false);
												setSubmitDisabled(false);
												setPropertySuperBuiltUpMaxAreaError(false);
											}
										}}
										error={errorMaxSba || propertySuperBuiltUpMaxAreaError}
										helperText={errorMaxSba || propertySuperBuiltUpMaxAreaError ? localLang.valueLessThanMinArea : ""}
										label={localLang.maxArea}
									/>
								</TextContainer>

								<FormControl
									sx={{
										width: "100%",
										"& .MuiOutlinedInput-root": {
											borderRadius: "0.75rem",
										},
									}}
								>
									<InputLabel id="demo-simple-select-helper-label">{localLang.unit}</InputLabel>
									<Select
										labelId="demo-simple-select-helper-label"
										id="demo-simple-select-helper"
										value={superBuiltUpAreaUnit}
										sx={{
											width: "100%",
											height: "3.5rem",
											"& .MuiOutlinedInput-root": {
												borderRadius: "0.75rem",
											},
										}}
										MenuProps={{
											PaperProps: {
												sx: {
													"& .MuiMenu-list": {
														direction: router.locale === "ar-AE" ? "rtl" : "ltr",
													},
												},
											},
										}}
										label={localLang.unit}
										onChange={(event: any) => {
											setSuperBuiltUpAreaUnit(event.target.value);
											setCarpetAreaUnit(event.target.value);
											dispatch(setCreateNewPropertySuperBuiltUpAreaUnitThunk(event.target.value));
										}}
									>
										<MenuItem value="sqft">{localLang.sqft}</MenuItem>
										<MenuItem value="sqmt">{localLang.sqm}</MenuItem>
									</Select>
								</FormControl>
							</TextContainer>
						</TextContainer>
					)}
				</TextContainer>
			) : null}
		</PaperContainer>
	);
};

export default PropertyConfig;
